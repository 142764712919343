.bg-evdeinternet {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-evdeinternet-rgb), var(--bs-bg-opacity)) !important;
}
.bg-evdeinternet-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-evdeinternetdark-rgb), var(--bs-bg-opacity)) !important;
}

.btn-evdeinternet {
    color: #253342;
    background-color: #ffc900;
    border-color: #ffc900;
}
.btn-evdeinternet:hover {
    color: #253342;
    background-color: #ffc900;
    border-color: #ffc900;
}